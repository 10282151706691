.loading-screen {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vw;

    background-color: white;
    opacity: 0.6;
}

.loading-screen > * {
  color: var(--primary-color) !important;
  font-size: 3em;

  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-screen .spinner-grow {
    width: 5rem;
    height: 5rem;
}