/******************************
    Table of contents
    - General
    - Headings
    - Common
    - Links
    - Lists
_______________________________
*******************************/



.member.app-container {
    color: var(--normal-text-color);
    min-height: 100vh;
    background-color: var(--primary-color-very-very-light);
    padding-bottom: 4rem;
}

.member.app-container > .content {
    padding-top: 1rem;
    padding-left: 3rem;
    margin-left: 18rem;
}


@media (max-width: 1000px) {

  .member.app-container > .content {
      width: 90vw;
      padding-top: 0.5rem;
      padding-left: 0;
      margin: 0 auto;
  }
}



/******************************
    Headings
_______________________________
*******************************/

.member.app-container h2.custom-heading {
    color: var(--primary-color);
    font-size: var(--normal-font-size-large);
    font-weight: bold;
    margin-top: 3rem;
}



/******************************
    Links
_______________________________
*******************************/

a.block-link,
a.block-link:hover {
  text-decoration: none;
  color: var(--normal-text-color-light) !important;
}

a.non-text-link,
a.non-text-link:hover {
    text-decoration: none;
    color: initial;
}

.member.app-container a {
  color: var(--primary-color);
  font-size: var(--normal-font-size);
}

.member.app-container a.no-style {
  color: var(--secondary-color);
}

.member.app-container  a:hover {
  color: var(--secondary-color);
}

.member.app-container .main-heading {
  position: relative;
  margin-bottom: 2rem;
}

.member.app-container .main-heading h1 {
  display: inline;
  font-size: var(--normal-font-size-very-very-large);
}

.member.app-container .new-item-button.right {
  position: absolute;
  top: 0;
  right: 0;
}



/******************************
    List
_______________________________
*******************************/

.member.app-container .list-container {
  padding-top: 0;
}

.member.app-container .list .item.detailed.size-0 {
  background-color: #ffffff;
  min-height: 8rem;
  margin-bottom: 2rem;
  position: relative;
}

.member.app-container .list .item.detailed.basic-details {
  display: block;
  padding: 0.5rem;
  min-height: 4rem;
  margin-bottom: 1rem;
}


.member.app-container .list .item.detailed .thumbnail {
  width: 10.4rem;
  height: 8rem;
}


.member.app-container .list .item.detailed .details {
  font-size: var(--normal-font-size);
  height: 8rem;
  left: 12rem;
  padding: 0.25rem;
}

.member.app-container .list .item.detailed .details .custom-heading a,
.member.app-container .list .item.detailed.basic-details a {
  font-size: var(--normal-font-size-large);
}

.member.app-container .list .custom-heading a {
  text-decoration: none;
}

@media(max-width: 700px) {
  .member.app-container .list .item.detailed, .list .item.detailed .thumbnail, .list .item.detailed .details {
      position: inherit;
  }

  .member.app-container .list .item.detailed .details {
      left: 0;
  }
}

.member.app-container .list-group .list-group-item {
  color: var(--normal-text-color);
}

.member.app-container .list-group.no-border .list-group-item {
  border: none;
}

.member.app-container .list-container .list-group button.list-group-item {
  margin-bottom: 0.5rem;
}

.member.app-container .list-container .list-group button.list-group-item:hover {
  transform: scale(1.01);
}