.guest.app-container .top-nav {
    font-size: 1.25rem;
    font-weight: bold;
    position: relative;
}

.guest.app-container .top-nav img {
  height: 2rem;
}

.guest.app-container .top-nav ul {
    position: absolute;
    right: 0;
    z-index: 1;
}

.guest.app-container .top-nav ul li {
    list-style: none;
    padding: 0 0.5em;
}

.guest.app-container .top-nav.horizontal,
.guest.app-container .top-nav.horizontal ul {
    display: flex;
}

.guest.app-container .top-nav.vertical ul {
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0 4px 4px -2px rgba(0 0 0 / 0.2);
}

.guest.app-container .top-nav.vertical ul li {
    text-align: right;
    padding: 0.5rem 0;
}

.guest.app-container .top-nav.vertical .menu-icon {
    color: var(--primary-color);
    display: flex;
    position: absolute;
    font-size: 2rem;
    top: 0;
    right: 1rem;
    cursor: pointer;
}

.guest.app-container .top-nav a,
.guest.app-container .top-nav a:active {
  color: var(--primary-color);
}

.guest.app-container .top-nav a:hover,
.guest.app-container .top-nav.vertical .menu-icon:hover {
    color: var(--secondary-color);
}


@media (max-width: 1000px) {
    .guest.app-container .top-nav.horizontal {
        display: none;
    }

    .guest.app-container .top-nav.vertical {
        display: block;
    }
}

@media (min-width: 1001px) {
    .guest.app-container .top-nav.horizontal {
        display: flex;
    }

    .guest.app-container .top-nav.vertical {
        display: none;
    }
}