.template .schedule-settings {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 1rem;
}

.template .schedule-settings > * {
    margin-right: 3rem;
}

@media(max-width: 650px) {
    .template .schedule-settings {
        display: block;
    }   

    .template .schedule-settings > * {
        margin-bottom: 0.6rem;
    }
}