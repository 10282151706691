.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-container img {
    display: block;
    width: 12rem;
    margin: 0 auto;
}

.login-container .form {
    background-color: #ffffff;
    width: 25rem;
    min-height: 30rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

@media (max-width: 750px) {
    .login-container .form.rounded-corners {
        border-radius: 0;
    }

    .login-container .form.shadow {
        box-shadow: none !important;
    }

    .login-container .form {
        width: 100vw;
        min-height: 30rem;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.login-container .form .form-fields {
    width: 20rem;
    margin: 0 auto;
}

.login-container .form label {
    font-size: 1.2rem;
    display: block;
    color: var(--primary-color);
}

.login-container .form .form-group:first-of-type {
    margin-top: 3rem;
}

.login-container .form .form-group {
    margin-bottom: 2rem;
}

.login-container .form input {
    color: var(--normal-text-color) !important;
    min-width: 20rem;
    padding: 0.25rem;
    border-radius: 0.4rem;
    border: 1px var(--light-grey-color) solid;
}

.login-container .form input:active {
  outline: 2px var(--primary-color-very-light) solid;
}

.login-container .form input:focus {
  outline: 2px var(--primary-color-very-light) solid;
  color: var(--normal-text-color) !important;
}

.login-container .form button {
    margin-right: 1rem !important;
}

a.login-alternative,
.sign-up-terms a {
    color: var(--secondary-color);
}

a.login-alternative:hover,
.sign-up-terms a:hover {
    color: var(--primary-color);
}

.login-container .form .block-text {
    margin-top: 0.5rem;
}