.journal-entries .image-section img {
    max-width: 30rem;
    max-height: 30rem;
    margin-bottom: 1rem;
}

@media(max-width: 600px) {
    .journal-entries .image-section img {
        max-width: 100%;
    }
}