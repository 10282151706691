.member.app-container .nav-container {
    position: fixed;
    width: 18rem;
    height: 100vh;
    background-color: #ffffff;
    z-index: 1;
}

@media(max-width: 1000px) {
    .member.app-container .nav-container {
        width: auto;
        height: initial;
    } 
}

.member.app-container .account-menu-drop-down {
    background-color: var(--primary-color) !important;
    border-radius: 1.5rem !important;
    border: none !important;
}


.member.app-container .top-nav {
    font-size: 1.25rem;
    font-weight: bold;
    position: relative;
}

.member.app-container .top-nav img {
  height: 2rem;
  padding-left: 3.5rem;
}

@media (max-width: 1100px) {
    .member.app-container .top-nav img {
        margin-top: 1.5rem;
        padding-left: 3rem;
    }
}

@media (max-height: 450px) {
    .member.app-container .top-nav img {
        margin-top: 0;
    }
}

.member.app-container .top-nav ul li {
    list-style: none;
    padding: 0 0.5em;
}

.member.app-container .top-nav.desktop-devices {
    width: 18rem;
    height: 100vh;
    background-color: #ffffff;
    padding: 1rem 0;
    position: relative;
    top: 0;
}

.member.app-container .top-nav.mobile-devices {
    padding: 0.5rem 0;      
    border-bottom: #ffffff solid 2rem;
    position: relative;
    top: 0;
}

.member.app-container .top-nav.desktop-devices ul {
    padding-top: 3rem;
}

.member.app-container .top-nav.desktop-devices ul > li {
    padding-top: 1rem;
}

.member.app-container .top-nav.mobile-devices ul {
    background-color: #ffffff;
    box-shadow: 0 4px 4px -2px rgba(0 0 0 / 0.2);
}

.member.app-container .top-nav.mobile-devices ul li {
    text-align: right;
    padding: 0.5rem 0;
}

.member.app-container .top-nav.mobile-devices .menu-icon {
    color: var(--primary-color);
    display: flex;
    font-size: 2rem;
    padding: 0.25rem;
    position: absolute;
    top: 0.5rem;
    cursor: pointer;
}


.member.app-container .top-nav.top-nav.mobile-devices .custom-nav {
    position: relative;
    width: 18rem;
    height: 85vh;
    margin: 3.5rem auto 0 auto;
    padding-top: 0.5rem;
    background-color: #ffffff;
}

.member.app-container .top-nav.top-nav.mobile-devices .custom-nav ul {
    padding-top: 3rem;
    box-shadow: none !important;
}

@media (max-height: 450px) {
    .member.app-container .top-nav.top-nav.mobile-devices .custom-nav ul {
        padding-top: 1.5rem;
    }
}

.member.app-container .top-nav.top-nav.mobile-devices .custom-nav li {
    text-align: left;
}

.member.app-container .top-nav.top-nav.mobile-devices .custom-nav .account-buttons {
    color: var(--normal-text-color-light);
    font-size: 1.1rem;
    text-align: left;
    margin: auto;
}

.member.app-container .top-nav.top-nav.mobile-devices .custom-nav .account-buttons a,
.member.app-container .top-nav.top-nav.mobile-devices .custom-nav .account-buttons a:active {
    color: var(--normal-text-color-light);
    text-decoration: none;
}

.member.app-container .top-nav.top-nav.mobile-devices .custom-nav .account-buttons a:hover {
    color: var(--normal-text-color);
}

.member.app-container .top-nav.top-nav.mobile-devices .custom-nav .account-buttons svg {
    margin-right: 0.5rem;
}

.member.app-container .top-nav a,
.member.app-container .top-nav a:active {
  color: var(--primary-color);
}

.member.app-container .top-nav a:hover,
.member.app-container .top-nav.mobile-devices .menu-icon:hover {
    color: var(--secondary-color);
}


@media (max-width: 1000px) {
    .member.app-container .top-nav.desktop-devices {
        display: none;
    }

    .member.app-container .top-nav.mobile-devices {
        display: block;
    }

    .member.app-container .top-nav.top-nav.mobile-devices .custom-nav .account-buttons {
        padding-left: 3rem;
    }
}

@media (min-width: 1001px) {
    .member.app-container .top-nav.mobile-devices {
        display: none;
    }
}