@import url('https://fonts.googleapis.com/css2?family=Quicksand');


/******************************
    Table of contents
    - General
    - Fonts
    - Buttons
    - Links
    - Common
    - Home - guest user
    - Lists
    - Lists - medium length content
    - Videos
    - Challenges
_______________________________
*******************************/

:root {
    --primary-color: #ff4208;
    --primary-color-very-light: #f8d5ca;
    --primary-color-very-very-light: #fffbfa;
    --secondary-color: #3C6648;
    --secondary-color-very-light: #63856d;
    --secondary-color-very-very-light: #b1c2b6;
    --normal-text-color: #45634e;
    --normal-text-color-light: #6e8675;
    --off-white-color: #fffbfa;
    --off-white-color-dark: #ffece6;
    --light-grey-color: rgba(0 0 0 / 0.3);
    --normal-font-size: 1rem;
    --normal-font-size-large: 1.3rem;
    --normal-font-size-very-large: 1.5rem;
    --normal-font-size-very-very-large: 1.8rem;
}

* {
    box-sizing: border-box;
}

.body {
    width: 80vw;
    margin: auto;
}

.guest.app-container {
  color: var(--normal-text-color);
  margin: 1em 10em 10em 10em;
}

/******************************
    Fonts
_______________________________
*******************************/

body, .app-container {
    font-family: 'Quicksand', sans-serif;
}

.app-container h1, .app-container h2 {
    font-weight: bold;
}

.app-container .guest-section h1, .app-container .guest-section h3 {
    color: var(--primary-color)
}

.app-container .guest-section h2 {
    color: var(--secondary-color)
}

.custom-color-primary {
    color: var(--primary-color);
}




/******************************
    Buttons
_______________________________
*******************************/

button,
.custom-button {
    padding: 0.5em 1em;
    box-shadow: none;
    border-radius: 0.5rem !important;
}

button:hover,
.custom-button:hover {
    transform: scale(1.02);
}

button:disabled {
    cursor: not-allowed;
    background-color: var(--primary-color-very-light) !important;
}

button.primary:active,
.custom-button.primary:active {
    border: 3px var(--primary-color-very-light) solid !important;
}

button.primary,
.custom-button.primary {
  color: #ffffff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  outline-color: var(--primary-color-very-light);
  border: #ffffff solid !important;
}

button.secondary,
.custom-button.secondary {
  color: #ffffff;
  background-color: var(--secondary-color-very-light);
  outline-color: var(--secondary-color-very-light) !important;
  border: 1px var(--secondary-color-very-light) solid !important;
}

button.secondary:active,
.custom-button.secondary:active {
    border: 3px var(--primary-color-very-very-light) solid !important;
}

button.secondary-outline,
.custom-button.secondary-outline {
  color: var(--secondary-color);
  background-color: #ffffff;
  outline-color: var(--secondary-color-very-light) !important;
  border: 1px var(--secondary-color-very-light) solid !important;
}

button.secondary-outline:active,
.custom-button.secondary-outline:active {
    border: 3px var(--primary-color-very-very-light) solid !important;
}

a.custom-button {
    text-decoration: none;
}

.link-button {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
}

.link-button:hover {
  color: var(--secondary-color);
}



/******************************
    Links
_______________________________
*******************************/


a {
  outline: none;
}



/******************************
    Common
_______________________________
*******************************/

.rounded-corners {
    border-radius: 0.8rem;
}

.shadow  {
    box-shadow: 0 4px 4px -2px rgba(0 0 0 / 0.2);
}

.clickable {
    cursor: pointer;
}

.clickable:active {
    filter: brightness(95%);
    background-color: #fafafa;
    box-shadow: 0 2px 3px rgba(0 0 0 / 0.3);
}

.background-light {
  background-color: #ffffff;
}

.section-card-container {
  width: 50vw;
}

.section-card {
  margin-top: 0;
  margin-bottom: 3rem;
  padding: 2rem 0;
}

@media (max-width: 1000px) {
  .section-card {
      width: 90vw;
      padding: 1rem 2rem;
  }
}

@media(max-width: 600px) {
  .section-card {
    padding: 1rem 0.5rem;
  }
}

.section-card-small {
  background-color: #ffffff;
  width: 30rem;
  min-height: 30rem;
  margin-bottom: 2rem;
}



/******************************
    Home - guest user
_______________________________
*******************************/

.guest-home-container {
    color: var(--primary-color);
    background-color: #ffffff;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.welcome-section {
  width: 60em;
}

.welcome-section .logo img {
  width: 30em;
  margin-bottom: 3em;
}

.tagline {
  font-weight: bold;
  font-size: 2.5em;
  margin-bottom: 2em;
}

.social-media-links span {
  font-size: 2em;
}
.social-media-links .icons {
  margin-top: 1.5em;
}

.social-media-links .icons > * {
  font-size: 3em;
  margin-right: 1em;
}

.social-media-links .icons a {
    color: var(--primary-color);
}

@media (max-height: 700px) {
    .guest-home-container {
        margin-top: 5rem;
    }
}

@media (max-width: 1200px) {
  .welcome-section {
    width: 90vw;
  }
}

@media (max-width: 600px) {
  .welcome-section .logo img {
    margin-top: 2em;
    width: 80%;
  }

  .tagline {
    font-size: 1.75em;
    margin-bottom: 2em;
  }
  
  .social-media-links span {
    font-size: 1.25em;
  }
  
  .social-media-links .icons > * {
    font-size: 2em;
  }
}

.get-started {
    display: flex;
}

.get-started a.call-to-action {
    background-color: var(--primary-color);
    color: #ffffff;
    text-decoration: none;
    font-size: 1.5rem;
    width: 12rem;
    height: 6rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    border: 1px var(--primary-color) solid;
    border-radius: 0.25em;
    cursor: pointer;
    margin-bottom: 2.5em;
    margin-right: 5rem;
}

.get-started a.call-to-action:hover {
    background-color: #ffffff;
    color: var(--primary-color);
}

@media (max-width: 500px) {
  .get-started {
      flex-direction: column;
  }

  .get-started a.call-to-action {
    width: 90vw;
    height: 2.8rem;
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .get-started a.call-to-action {
      display: inline-block;
  }
}


/******************************
    Lists
_______________________________
*******************************/
.list-container {
  padding-top: 3rem;
}

.list .detailed {
  min-height: 11.25rem;
  position: relative;
}

.list .item.detailed.clickable {
    cursor: pointer;
}

.list .item.detailed.clickable:active {
    filter: brightness(95%);
    background-color: #fafafa;
    box-shadow: 0 2px 3px rgba(0 0 0 / 0.3);
}

.list .item.detailed {
  display: flex;
  margin-bottom: 4rem;
  border-radius: 0.8rem;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0 0 0 / 0.2);
}

.list .item.detailed .thumbnail {
    width: 20rem;
    height: 11.25rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
}

.list .item.detailed .thumbnail > * {
    font-size: 3.5rem;
    color: var(--primary-color);
    opacity: 0.6;
    background-color: #ffffff;
    border-radius: 50%;
    border: #ffffff 1px solid;
}

.list .item.detailed .thumbnail:hover {
    transform: scale(1.02);
    transition: transform 0.2s ease-in;
}

.list-container a {
    text-decoration: none;
}

.list .item.detailed .details .description {
    color: var(--normal-text-color);
}

.list .item.detailed .details {
  font-size: 1.3rem;
  height: 11.25rem;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 20rem;
}

.list .item.detailed .details:hover {
    transform: scale(1.005);
    transition: transform 0.1s ease-in;
}


@media (max-width: 1100px) {
  .list .item.detailed .details {
      font-size: 1.1rem;
  }

  .guest.app-container {
      margin-right: 5rem;
      margin-left: 5rem;
  }
}

@media (max-width: 900px) {

  .guest.app-container {
      margin-right: 1.5rem;
      margin-left: 1.5rem;
  }

  .list .item.detailed .title {
      font-size: 1.3rem;
  }

  .list .item.detailed .thumbnail {
      width: 17.8rem;
      height: 10rem;
  }

  .list .item.detailed .details {
      left: 17.8rem;
      text-overflow: ellipsis;
  }

  .list .item.detailed {
      min-height: 10rem;
  }
}

@media (max-width: 700px) {
    .list .item.detailed {
        display: block;
    }

    .guest.app-container .list .item.detailed,
    .guest.app-container .list .item.detailed .thumbnail,
    .guest.app-container .list .item.detailed .details {
        position: static;
    }

    .list .item.detailed .thumbnail {
      width: 100%;
      height: 20em;
    }
}

@media (max-width: 500px) {

    .list .item.detailed .thumbnail {
      width: 100%;
      height: 15em;
    }
}



/******************************
    Lists - medium length content
_______________________________
*******************************/
.list.medium-length-content .item.detailed {
    display: block;
    padding: 1em;
}

.list.medium-length-content .item.detailed .title {
    font-size: 1.25rem;
}

.list.medium-length-content .item.detailed textarea,
.list.medium-length-content .item.detailed input[type="text"],
.list.medium-length-content .item.detailed input[type="number"],
.list.medium-length-content .item.detailed input[type="date"],
.list.medium-length-content .item.detailed select {
    min-width: 40rem;
    border-radius: 0.4rem;
    border: 1px var(--light-grey-color) solid;
}

.list.medium-length-content .item.detailed textarea:active,
.list.medium-length-content .item.detailed input:active,
.list.medium-length-content .item.detailed select:active {
  outline: 2px var(--primary-color-very-light) solid;
}

.list.medium-length-content .item.detailed textarea:focus,
.list.medium-length-content .item.detailed input:focus,
.list.medium-length-content .item.detailed select:focus {
  outline: 2px var(--primary-color-very-light) solid;
}

@media (max-width: 750px) {
  .list.medium-length-content .item.detailed textarea {
      min-width: 100%;
  }
}

/******************************
    Videos
_______________________________
*******************************/
.video-player-modal iframe {
  width: 47rem;
  height: 26.4rem;
}


@media (max-width: 1000px) {
  .video-player-modal iframe {
      width: 29rem;
      height: 16.3rem;
  }
}


@media (max-width: 500px) {
  .video-player-modal iframe {
      width: 27rem;
      height: 15.2rem;
  }
}


@media (max-width: 470px) {
  .video-player-modal iframe {
      width: 23rem;
      height: 12.9rem;
  }
}

@media (max-width: 400px) {
  .video-player-modal iframe {
      width: 18rem;
      height: 10.1rem;
  }
}



/******************************
    Challenges
_______________________________
*******************************/
.item.detailed.challenge .day-label {
    background-color: var(--secondary-color) !important;
}

.item.detailed.challenge .question-list {
    padding-top: 1.5rem;
}

.item.detailed.challenge .question-details {
    padding: 1rem 0;
}