.reminders {
    margin-bottom: 3rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.reminder {
    min-width: 15rem;
    max-width: 20rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
}

.reminder > div {
    height: 7rem;
}

.reminder .status-icon {
    font-size: 0.5rem;
}

.reminder .status-icon.overdue {
    color: var(--primary-color);
}

.reminder .status-icon.not-started {
    color: var(--primary-color-very-very-light);
}
